.resume-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

h1, h2, h3, h4 {
  color: #0056b3;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

p {
  line-height: 1.6;
}

